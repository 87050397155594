// Reservation.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Reservation.module.css";
import { ResponsiveContainer } from '../../components/ResponsiveContainer';

const Reservation = () => {
    const [isPartnerOptionRandom, setIsPartnerOptionRandom] = useState(false);
    const [partnerStyle, setPartnerStyle] = useState("긍정적");
    const [partnerPurpose, setPartnerPurpose] = useState("보험 가입 의향 고객");
    const [practiceCallNumber, setPracticeCallNumber] = useState("");
    const navigate = useNavigate();

    const handleRandomOptionClick = (option) => {
        setIsPartnerOptionRandom(option === "yes");
        if (option === "yes") {
            setPartnerStyle("랜덤");
            setPartnerPurpose("랜덤");
        } else {
            setPartnerStyle("긍정적");
            setPartnerPurpose("보험 가입 의향 고객");
        }
    };

    const handlePhoneNumberChange = (e) => setPracticeCallNumber(e.target.value);

    const isPhoneNumberValid = /^010-\d{4}-\d{4}$/.test(practiceCallNumber);

    const handleNextClick = () => {
        const selectedOptions = {
            isPartnerOptionRandom,
            partnerStyle,
            partnerPurpose,
            practiceCallNumber
        };
        navigate("/timeselection", { state: selectedOptions });
    };

    const purposes = ["보험 가입 의향 고객", "단순 정보 탐색 고객", "비교 분석 고객", "DB 마케팅 유입 고객", "자기 보험금 대리 청구 고객"];

    return (
        <div className={styles.page}>
            <div className={styles.mainContainer}>
                <ResponsiveContainer width={880} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                    <h2 className={styles.title}>연습할 파트너 옵션을<br />선택해주세요</h2>

                    <div className={styles.optionGroup}>
                        <p className={styles.optionTitle}>랜덤</p>
                        <div className={styles.flexBlank} />
                        <div className={styles.optionButtonGroup}>
                            <button
                                className={`${styles.optionButton} ${isPartnerOptionRandom ? styles.selected : ""}`}
                                onClick={() => handleRandomOptionClick("yes")}
                            >
                                예
                            </button>
                            <button
                                className={`${styles.optionButton} ${!isPartnerOptionRandom ? styles.selected : ""}`}
                                onClick={() => handleRandomOptionClick("no")}
                            >
                                아니오
                            </button>
                        </div>
                    </div>

                    {!isPartnerOptionRandom && (
                        <>
                            <div className={styles.optionGroup}>
                                <p className={styles.optionTitle}>대화 성향</p>
                                <div className={styles.flexBlank} />
                                <div className={styles.optionButtonGroup}>
                                    <button
                                        className={`${styles.optionButton} ${partnerStyle === "긍정적" ? styles.selected : ""}`}
                                        onClick={() => setPartnerStyle("긍정적")}
                                    >
                                        긍정적
                                    </button>
                                    <button
                                        className={`${styles.optionButton} ${partnerStyle === "부정적" ? styles.selected : ""}`}
                                        onClick={() => setPartnerStyle("부정적")}
                                    >
                                        부정적
                                    </button>
                                </div>
                            </div>

                            <div className={styles.optionGroup}>
                                <p className={styles.optionTitle}>상담 목적</p>
                                <div className={styles.flexBlank} />
                                <div className={styles.optionButtonColumnGroup}>
                                    {Array.from({ length: Math.ceil(purposes.length / 3) }, (_, groupIndex) => (
                                        <div key={groupIndex} className={styles.optionButtonGroup}>
                                            {purposes
                                                .slice(groupIndex * 3, groupIndex * 3 + 3)
                                                .map((purpose) => (
                                                    <button
                                                        key={purpose}
                                                        className={`${styles.optionButton} ${partnerPurpose === purpose ? styles.selected : ""}`}
                                                        onClick={() => setPartnerPurpose(purpose)}
                                                    >
                                                        {purpose}
                                                    </button>
                                                ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}

                    <div className={styles.optionGroup}>
                        <label className={styles.optionTitle}>연습 전화 받을 번호</label>
                        <div className={styles.flexBlank} />
                        <input
                            type="tel"
                            className={styles.phoneInput}
                            placeholder="전화번호를 입력해주세요"
                            value={practiceCallNumber}
                            onChange={handlePhoneNumberChange}
                        />
                    </div>
                    <div className={styles.flexBlank} />
                    <div className={styles.optionGroup} style={{marginBottom: 0}}>
                        <div className={styles.flexBlank} />
                        <button
                            className={`${styles.nextButton} ${isPhoneNumberValid ? styles.primary : styles.disabled}`}
                            onClick={handleNextClick}
                            disabled={!isPhoneNumberValid}
                        >
                            다음
                        </button>
                    </div>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Reservation;