// CustomDatePicker.js
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './CustomDatePicker.css';
import styles from './CustomDatePicker.module.css'

// 날짜만 선택할 수 있는 DatePicker
const CustomDatePicker = ({ selectedDate, onChange }) => {
  return (
    <DatePicker
      className={styles.CustomDatePicker}
      selected={selectedDate}
      onChange={(date) => onChange(date)}
      dateFormat="yyyy-MM-dd" // 날짜 형식 설정
      placeholderText="날짜를 선택해주세요"
    />
  );
};

export default CustomDatePicker;