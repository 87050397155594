import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Main.module.css";
import { useFiles } from "../../contexts/FileContext";
import FileList from "./../../components/FileList/FileList";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import Electirc from "../../assets/images/Electric.png";

const Main = () => {
  const navigate = useNavigate();
  const { files } = useFiles();
  const tutorialPdf = process.env.PUBLIC_URL + "/roleplay_tutorial.pdf";
  const handleNavigation = (path) => {
    navigate(path);
  };

  const handleFileClick = (file) => {
    if (file.status !== "complete") {
      navigate("/practice", { state: { selectedFile: file } });
    } else {
      navigate("/feedback", { state: { selectedFile: file } });
    }
  };

  const downloadFile = () => {
    const link = document.createElement("a");
    link.href = tutorialPdf;
    link.download = "RolePlay_Tutorial.pdf";
    link.click();
  };

  return (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
        <ResponsiveContainer width={880}>
          <div className={styles.header}>
            <p className={styles.practiceListText}>상담 목록</p>
            <button
              onClick={() => handleNavigation("/reservation")}
              className={styles.reserveButton}
            >
              연습 예약하기
            </button>
          </div>
          <FileList files={files} onFileClick={handleFileClick} />
          <div onClick={downloadFile} className={styles.tutorialButton}>
            <img src={Electirc} className={styles.electircIcon} />
            <div>빠른 사용법</div>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Main;
