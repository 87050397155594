import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./PracticeCheck.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import PracticeJandi from "../../components/PracticeJandi/PracticeJandi";
import WeekCalendar from "../../components/WeekCalendar/WeekCalendar";
import { useFiles } from "../../contexts/FileContext";
import { useUser } from "../../contexts/UserContext";
import { usePractices } from "../../contexts/PracticeContext";
import ProfileImage from "../../assets/images/Profile.png";
import ProfileAddImage from "../../assets/images/ProfileAdd.png";
import ReactGA from "react-ga4";

const PracticeCheck = () => {
  const [selectedUser, setSelectedUser] = useState(null); // 선택된 사용자
  const [teamData, setTeamData] = useState([]); // 팀원 데이터
  // const [filteredFiles, setFilteredFiles] = useState([]); // 선택된 유저의 파일
  // const [filteredPractices, setFilteredPractices] = useState([]); // 선택된 유저의 연습 데이터
  // const { userData, fetchUserByEmail } = useUser(); // 사용자 데이터 컨텍스트
  // const { fetchFilesByUserId } = useFiles(); // 파일 컨텍스트
  // const { getPracticesByUser } = usePractices(); // 연습 컨텍스트
  // const navigate = useNavigate();

  // // 팀원 데이터 초기화
  // useEffect(() => {
  //   const initializeTeamData = async () => {
  //     if (userData) {
  //       try {
  //         const teamData = await Promise.all(
  //           (userData.teamMembers || []).map(async (member) => {
  //             const user = await fetchUserByEmail(member.email);
  //             return {
  //               email: member.email,
  //               name: member.name || "팀원",
  //               userId: user?.userId || null,
  //             };
  //           })
  //         );

  //         setSelectedUser({
  //           email: userData.email,
  //           name: "나",
  //           userId: userData.userId,
  //         });
  //         setTeamData([
  //           { email: userData.email, name: "나", userId: userData.userId },
  //           ...teamData,
  //         ]);
  //       } catch (error) {
  //         console.error("팀원 데이터 초기화 오류:", error);
  //       }
  //     }
  //   };

  //   initializeTeamData();
  // }, [userData, fetchUserByEmail]);

  // // 선택된 사용자의 데이터 가져오기
  // useEffect(() => {
  //   const fetchFilesAndPractices = async () => {
  //     if (selectedUser?.userId) {
  //       try {
  //         // Firestore에서 선택된 사용자의 파일 데이터 가져오기
  //         const userFiles = await fetchFilesByUserId(selectedUser.userId);
  //         setFilteredFiles(userFiles);

  //         // Firestore에서 선택된 사용자의 연습 데이터 가져오기
  //         const userPractices = await getPracticesByUser(selectedUser.userId);
  //         setFilteredPractices(userPractices);
  //       } catch (error) {
  //         console.error("선택된 유저 데이터 가져오기 오류:", error);
  //       }
  //     } else {
  //       // 선택된 사용자가 없을 때 데이터 초기화
  //       setFilteredFiles([]);
  //       setFilteredPractices([]);
  //     }
  //   };

  //   fetchFilesAndPractices();
  // }, [selectedUser, fetchFilesByUserId, getPracticesByUser]);

  const { userData } = useUser();
  const { files, teamFiles } = useFiles(); // 모든 파일 데이터
  const { practices, teamPractices } = usePractices(); // 모든 연습 데이터
  const navigate = useNavigate();
  useEffect(() => {
    if (userData) {
      const teamData = [
        { email: userData.email, name: "나", userId: userData.userId },
        ...(userData.teamMembers || []),
      ];
      setTeamData(teamData);
      setSelectedUser(teamData[0]); // Defaults to the logged-in user
    }
  }, [userData]);

  const filteredFiles = selectedUser?.userId
    ? files
        .concat(teamFiles)
        .filter((file) => file.userId === selectedUser.userId)
    : [];
  const filteredPractices = selectedUser?.userId
    ? practices
        .concat(teamPractices)
        .filter((practice) => practice.userId === selectedUser.userId)
    : [];

  const goToAddMember = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Add Member Button",
      label: "Add Team Member",
    });
    navigate("/addmember");
  };

  return (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
        <ResponsiveContainer
          width={880}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
          }}
        >
          <h2 className={styles.title}>연습 체크</h2>

          <div className={styles.profileContainer}>
            {teamData.map((member, index) => (
              <div
                key={index}
                className={styles.profileCard}
                style={{
                  border:
                    selectedUser?.email === member.email
                      ? "1px solid var(--primary-normal)"
                      : "none",

                  backgroundColor:
                    selectedUser?.email === member.email
                      ? "var(--primary-chip)"
                      : "white",
                }}
                onClick={() => setSelectedUser(member)}
              >
                <img src={ProfileImage} className={styles.profileImage} />
                <div className={styles.profileName}>{member.name}</div>
                <div className={styles.profileEmail}>{member.email}</div>
              </div>
            ))}
            <div className={styles.addTeamBtn} onClick={goToAddMember}>
              <img src={ProfileAddImage} className={styles.profileImage} />
              <div className={styles.addTeamContent}>팀원 추가</div>
            </div>
          </div>

          {/* WeekCalendar */}
          <div className={styles.weekCalendarContainer}>
            <WeekCalendar
              files={filteredFiles}
              practices={filteredPractices}
              onIndicatorClick={(file) =>
                navigate("/feedback", { state: { selectedFile: file } })
              }
              onPracticeClick={(practice) =>
                navigate("/viewpractice", {
                  state: { selectedPractice: practice },
                })
              }
            />
          </div>
          {/* PracticeJandi */}
          <h3 className={styles.subtitle}>2024년 전체 연습</h3>
          <div className={styles.jandiContainer}>
            <PracticeJandi files={filteredFiles} />
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PracticeCheck;
