import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { UserModel } from "../models/UserModel";

// users 컬렉션 이름 상수
const USERS_COLLECTION = "users";
/**
 * 사용자를 생성하고 Firestore에 저장합니다.
 * @param {UserModel} user - 생성할 사용자 객체
 * @returns {Promise<void>}
 */
export const createUserInService = async (user) => {
  try {
    const userDocRef = doc(db, USERS_COLLECTION, user.userId);
    await setDoc(userDocRef, user.toJson());
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};
export const updateUserPhoneNumberInService = async (userId, phoneNumber) => {
  const userDocRef = doc(db, "users", userId);
  await updateDoc(userDocRef, { phoneNumber }); // 전화번호 업데이트
};

/**
 * 특정 userId로 Firestore에서 사용자 데이터를 가져옵니다.
 * @param {string} userId - 가져올 사용자의 문서 ID
 * @returns {Promise<UserModel|null>}
 */
export const getUserInService = async (userId) => {
  try {
    const userDocRef = doc(db, USERS_COLLECTION, userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      return UserModel.fromJson({ userId, ...userDoc.data() });
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};
/**
 * 특정 userId로 Firestore에서 사용자 데이터를 업데이트합니다.
 * @param {string} userId - 업데이트할 사용자의 문서 ID
 * @param {Object} updatedData - 업데이트할 데이터 객체
 * @returns {Promise<void>}
 */
export const updateUserInService = async (userId, updatedData) => {
  try {
    const userDocRef = doc(db, USERS_COLLECTION, userId);
    await updateDoc(userDocRef, updatedData);
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

/**
 * 특정 userId로 Firestore에서 사용자 데이터를 삭제합니다.
 * @param {string} userId - 삭제할 사용자의 문서 ID
 * @returns {Promise<void>}
 */
export const deleteUserInService = async (userId) => {
  try {
    const userDocRef = doc(db, USERS_COLLECTION, userId);
    await deleteDoc(userDocRef);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
/**
 * 특정 datetime에 대해 사용 가능한 partner user의 uid를 반환하는 함수
 * @param {string} datetime - 확인할 datetime 값
 * @returns {Promise<UserModel|null>} - 사용 가능한 partner user의 uid 또는 null
 */
export const getAvailablePartnerInService = async (reservationDateTime) => {
  try {
    const partnersQuery = query(
      collection(db, USERS_COLLECTION),
      where("role", "==", "partner")
    );
    const partnersSnapshot = await getDocs(partnersQuery);
    for (const partnerDoc of partnersSnapshot.docs) {
      const partnerData = partnerDoc.data();
      const partnerId = partnerData.userId;
      const filesQuery = query(
        collection(db, "files"),
        where("partnerId", "==", partnerId),
        where("practiceDateTime", "==", reservationDateTime)
      );
      const filesSnapshot = await getDocs(filesQuery);
      // 파일이 존재하지 않으면 현재 partner가 사용 가능함
      if (filesSnapshot.empty) {
        return UserModel.fromJson({ partnerId, ...partnerData });
      }
    }

    // 모든 partner가 사용 중인 경우 null 반환
    return null;
  } catch (error) {
    console.error("Error finding available partner:", error);
    throw error;
  }
};

/**
 * 팀 멤버를 추가합니다.
 * @param {string} currentUserId - 현재 로그인한 사용자 ID
 * @param {string} memberEmail - 추가할 멤버의 이메일
 * @param {string} memberName - 추가할 멤버의 이름
 * @returns {Promise<{userId: string, email: string, name: string}>} - 추가된 멤버의 정보
 */
export const addMemberToTeamInService = async (
  currentUserId,
  memberEmail,
  memberName
) => {
  try {
    // 이메일로 사용자 검색
    const usersCollection = collection(db, USERS_COLLECTION);
    const emailQuery = query(
      usersCollection,
      where("email", "==", memberEmail)
    );
    const querySnapshot = await getDocs(emailQuery);

    if (querySnapshot.empty) {
      throw new Error("존재하지 않는 이메일입니다."); // 사용자가 존재하지 않을 경우
    }

    // 이메일로 찾은 사용자 문서 참조
    const userDoc = querySnapshot.docs[0];
    const memberUserId = userDoc.id; // 멤버의 Firestore 문서 ID

    // 현재 사용자의 `teamMembers` 배열에 새 멤버 추가 (userId 포함)
    const currentUserRef = doc(db, USERS_COLLECTION, currentUserId);
    await updateDoc(currentUserRef, {
      teamMembers: arrayUnion({
        userId: memberUserId, // userId 추가
        email: memberEmail,
        name: memberName,
      }),
    });

    // 성공적으로 추가된 멤버 반환
    return { userId: memberUserId, email: memberEmail, name: memberName };
  } catch (error) {
    console.error("Error adding member to team:", error);
    throw error;
  }
};

/**
 * 이메일로 사용자 정보를 가져옵니다.
 * @param {string} email - 사용자 이메일
 * @returns {Promise<UserModel|null>}
 */
export const getUserByEmailInService = async (email) => {
  try {
    const usersCollection = collection(db, USERS_COLLECTION);
    const emailQuery = query(usersCollection, where("email", "==", email));
    const querySnapshot = await getDocs(emailQuery);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { userId: userDoc.id, ...userDoc.data() };
    } else {
      return null; // 해당 이메일을 가진 사용자가 없음
    }
  } catch (error) {
    console.error("Error fetching user by email:", error);
    throw error;
  }
};

/**
 * 기존 사용자 데이터를 기본값 형식으로 업데이트합니다.
 */
export const migrateUsersToDefaultStructure = async () => {
  try {
    const usersCollectionRef = collection(db, "users");
    const snapshot = await getDocs(usersCollectionRef);

    // 기본값 설정
    const defaultUserStructure = {
      userId: null,
      name: null,
      email: null,
      password: null,
      createdDate: null, // ISO 8601: YYYY-MM-DDTHH:MM:SS.sssZ
      isSubscribed: false,
      subscriptionStartDate: null, // ISO 8601: YYYY-MM-DD
      subscriptionEndDate: null, // ISO 8601: YYYY-MM-DD
      role: "customer", // admin, partner, customer
      slackUserId: null,
      teamMembers: [], // 빈 배열
    };

    const updates = [];
    snapshot.forEach((docSnapshot) => {
      const data = docSnapshot.data();
      const userId = docSnapshot.id;

      // 변경 사항 정의
      const updatesForDoc = {};

      // 누락된 필드를 기본값으로 추가
      for (const key in defaultUserStructure) {
        if (!Object.prototype.hasOwnProperty.call(data, key)) {
          updatesForDoc[key] = defaultUserStructure[key];
        }
      }

      // 변경 사항이 있는 경우 Firestore 업데이트
      if (Object.keys(updatesForDoc).length > 0) {
        updates.push(updateDoc(doc(db, "users", userId), updatesForDoc));
      }
    });

    // 모든 업데이트 병렬 처리
    await Promise.all(updates);
    console.log(
      "마이그레이션 완료: 모든 사용자 데이터가 기본 구조로 업데이트되었습니다."
    );
  } catch (error) {
    console.error("마이그레이션 중 오류 발생:", error);
  }
};
