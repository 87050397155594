import React from "react";
import { useLocation } from "react-router-dom";
import styles from "./Practice.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";

function Practice() {
  const { state } = useLocation();
  const { selectedFile } = state || {};

  return (
    <div className={styles.container}>
      <div className={styles.customContainer}>
        {selectedFile && (
          <div>
            <p className={styles.p1Text}>{selectedFile.name}</p>
            <div className={styles.fileText}>

              <div className={styles.textcontainer2}>
                <p className={styles.p2Text}>상태</p>
                <ResponsiveContainer className={styles.customButton}>
                  <p className={styles.customButtonText}>
                    {selectedFile.status === "notStarted"
                      ? "시작 전"
                      : selectedFile.status === "inProgress"
                        ? "진행 중"
                        : selectedFile.status === "completed"
                          ? "완료"
                          : "알 수 없음"}
                  </p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textcontainer2}>
                <p className={styles.p3Text}>연습 시간</p>
                <ResponsiveContainer className={styles.customButton}>
                  <p className={styles.customButtonText}>
                    {new Date(selectedFile.practiceDateTime).toLocaleDateString("ko-KR", {
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })}{" "}
                    {new Date(selectedFile.practiceDateTime).toLocaleTimeString("ko-KR", {
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                  </p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textcontainer2}>
                <p className={styles.p4Text}>랜덤</p>
                <ResponsiveContainer className={styles.customButton}>
                  <p className={styles.customButtonText}>
                    {selectedFile.isPartnerOptionRandom ? "선택함" : "선택 안함"}
                  </p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textcontainer2}>
                <p className={styles.p5Text}>대화 성향</p>
                <ResponsiveContainer className={styles.customButton2}>
                  <p className={styles.customButtonText2}>{selectedFile.partnerStyle}</p>
                </ResponsiveContainer>
              </div>

              <div className={styles.textcontainer2}>
                <p className={styles.p6Text}>상담 목적</p>
                <ResponsiveContainer className={styles.customButton}>
                  <p className={styles.customButtonText}>{selectedFile.partnerPurpose}</p>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Practice;
