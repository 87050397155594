import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "./../../contexts/UserContext";
import styles from "./FeedBack.module.css";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import ReactGA from "react-ga4";

function FeedBack() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userData } = useUser();
  const { selectedFile } = state || {}; // 네비게이션 상태에서 선택된 파일 데이터 가져오기

  const goToMain = () => {
    navigate("/main");
  };

  // HTML 변환 함수: 태그 제거 + 줄바꿈 처리
  const convertHtmlToText = (html) => {
    if (!html) return ""; // HTML 데이터가 없을 경우 빈 문자열 반환

    // 줄바꿈 태그를 줄바꿈 문자로 변환
    html = html.replace(/<br\s*\/?>/gi, "\n");

    // 임시 div 요소를 생성해 HTML을 텍스트로 변환
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || ""; // 태그 제거 후 텍스트만 반환
  };

  const copyToClipboard = (type, html) => {
    // GA
    ReactGA.event({
      category: "Feedback Page",
      action: "copyToClipboard",
      label:
        type === "conversationFeedback"
          ? "Conversation Feedback"
          : type === "summaryFeedback"
          ? "Summary Feedback"
          : "",
    });

    // 기능
    const text = convertHtmlToText(html); // HTML 변환
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("클립보드에 복사되었습니다.");
      })
      .catch((err) => {
        console.error("클립보드에 복사하는 중 오류가 발생했습니다:", err);
      });
  };

  const saveAsTextFile = (filename, html) => {
    // GA
    ReactGA.event({
      category: "Feedback Page",
      action: "saveAsTextFile",
      label: filename,
    });

    // 기능
    const text = convertHtmlToText(html); // HTML 변환
    const blob = new Blob([text], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openRecordingLink = () => {
    // GA
    ReactGA.event({
      category: "Feedback Page",
      action: "openRecordingLink",
      label: "clovaLink",
    });

    // 기능
    if (selectedFile?.practiceRecordingLink) {
      window.open(selectedFile.practiceRecordingLink, "_blank");
    } else {
      alert("녹음 링크가 존재하지 않습니다.");
    }
  };

  const formatFeedback = (feedback) => {
    if (!feedback) return;
    const formattedFeedback = feedback
      .replace(/\[(\d+)\]\s*(.*?)<br \/>/g, "<strong>[$1] $2</strong><br />")
      .replace(/💭\s*\[기존\s상담\s내용\]/g, "💭 [기존 상담 내용]")
      .replace(/✅\s*\[개선\s방안\]/g, "✅ [개선 방안]")
      .replace(/👉\s*\[개선\s후\s내용\]/g, "👉 [개선 후 내용]")
      .replace(/\n/g, "<br />");

    return formattedFeedback;
  };

  // const formatSummaryFeedback = (feedback) => {
  //   // Replace patterns to match the desired structure
  //   return feedback
  //     .replace(
  //       /🌟 \[General Improvements\]/g,
  //       "<strong>🌟 [General Improvements]</strong><br />"
  //     )
  //     .replace(/• /g, "<br />• ")
  //     .replace(/\n/g, "<br /><br />");
  // };

  return (
    <div className={styles.container}>
      {selectedFile && (
        <div className={styles.customContainer}>
          <p className={styles.p1Text}>연습에 대한 피드백입니다</p>
          <div className={styles.textContainer}>
            <p className={styles.p2Text}>대화 개선 피드백</p>
            <ResponsiveContainer
              className={styles.customButton}
              onClick={() =>
                copyToClipboard(
                  "conversationFeedback",
                  selectedFile.conversationFeedback
                )
              }
            >
              <p className={styles.customButtonText}>복사하기</p>
            </ResponsiveContainer>
            <ResponsiveContainer
              className={styles.customButton}
              onClick={() =>
                saveAsTextFile(
                  "대화 개선 피드백.txt",
                  selectedFile.conversationFeedback
                )
              }
            >
              <p className={styles.customButtonText}>출력하기</p>
            </ResponsiveContainer>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                selectedFile.conversationFeedback ||
                "피드백 데이터가 없습니다.",
            }}
            // value={
            //   fileData.conversationFeedback || "피드백 데이터가 없습니다."
            // }
            disabled
            className={styles.inputField}
          />
          <div className={styles.textContainer2}>
            <p className={styles.p3Text}>종합 피드백</p>
            <ResponsiveContainer
              className={styles.customButton}
              onClick={() =>
                copyToClipboard("summaryFeedback", selectedFile.summaryFeedback)
              }
            >
              <p className={styles.customButtonText}>복사하기</p>
            </ResponsiveContainer>
            <ResponsiveContainer
              className={styles.customButton}
              onClick={() =>
                saveAsTextFile("종합 피드백.txt", selectedFile.summaryFeedback)
              }
            >
              <p className={styles.customButtonText}>출력하기</p>
            </ResponsiveContainer>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html:
                formatFeedback(selectedFile.summaryFeedback) ||
                "피드백 데이터가 없습니다.",
            }}
            // value={fileData.summaryFeedback || "피드백 데이터가 없습니다."}
            disabled
            className={styles.inputField2}
          />
          <div className={styles.textContainer3}>
            <p className={styles.p2Text}>연습 녹음본 링크</p>
            <ResponsiveContainer
              className={styles.customButton}
              onClick={openRecordingLink}
            >
              <p className={styles.customButtonText}>클로바 노트 조회하기</p>
            </ResponsiveContainer>
          </div>
          <ResponsiveContainer
            height={44}
            width={120}
            className={styles.customOkButton}
            onClick={goToMain}
          >
            <p className={styles.customOkButtonText}>확인</p>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}
export default FeedBack;
