// TimeSelection.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import CustomTimePicker from "../../components/CustomTimePicker/CustomTimePicker";
import { useUser } from "../../contexts/UserContext";
import { useFiles } from "../../contexts/FileContext";
import styles from "./TimeSelection.module.css";
import { getCurrentTimeWithMillisecondsISO } from "../../utils/isoDateUtils";
import Loading from "../Loading/Loading";
import slackSendMessage from "../../services/slackSendMessageService";
import { ResponsiveContainer } from "../../components/ResponsiveContainer";
import ReactGA from "react-ga4";

const TimeSelection = () => {
  const { userData, getAvailablePartner } = useUser();
  const { files, createFile } = useFiles();
  const navigate = useNavigate();
  const location = useLocation();
  const selectedOptions = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedMinute, setSelectedMinute] = useState(null);

  const handleNextClick = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    // 로그인 및 구독 상태 확인
    if (!userData.userId) {
      ReactGA.event({
        category: "TimeSelection Page",
        action: "Request Reservation",
        label: "Fail - User not logged in",
      });
      navigate("/reservation-complete", {
        state: { status: "needLogin", practiceDateTime: null },
      });
      return;
    }
    if (!userData.isSubscribed) {
      ReactGA.event({
        category: "TimeSelection Page",
        action: "Request Reservation",
        label: "Fail - User not subscribed",
      });
      navigate("/reservation-complete", {
        state: { status: "needSubscription", practiceDateTime: null },
      });
      return;
    }

    // 날짜, 시간, 분이 모두 선택된 경우 데이터 생성 및 이동
    if (selectedDate && selectedTime && selectedMinute !== null) {
      const practiceDateTime = new Date(selectedDate);
      practiceDateTime.setHours(selectedTime.getHours());
      practiceDateTime.setMinutes(selectedMinute);
      practiceDateTime.setSeconds(0);
      const koreaPracticeDateTime = new Date(
        practiceDateTime.getTime() + 9 * 60 * 60 * 1000
      )
        .toISOString()
        .slice(0, 19);

      const partner = await getAvailablePartner(koreaPracticeDateTime);

      if (partner === null) {
        ReactGA.event({
          category: "TimeSelection Page",
          action: "Request Reservation",
          label: "Fail - Time slot already reserved",
        });

        window.alert(
          "해당 시간에는 이미 예약이 완료되었습니다. 다른 시간으로 다시 시도해 주세요."
        );
        setIsLoading(false);
        return;
      }

      const fileData = {
        fileId: getCurrentTimeWithMillisecondsISO(),
        name: `#${files.length + 1}`,
        practiceDateTime: koreaPracticeDateTime,
        practiceCallNumber: selectedOptions.practiceCallNumber,
        isPartnerOptionRandom: selectedOptions.isPartnerOptionRandom,
        partnerStyle: selectedOptions.partnerStyle,
        partnerPurpose: selectedOptions.partnerPurpose,
        userId: userData.userId,
        partnerId: partner.userId,
      };

      await createFile(fileData);

      const slackMessage = `🔔 예약 알림 🔔\n안녕하세요, <@${partner.slackUserId}>님! 새로운 예약이 등록되었습니다.\n\n- *상태*: 예약되었습니다.\n- *예약 날짜*: ${fileData.practiceDateTime}\n- *예약자 UID*: ${fileData.userId}\n- *휴대폰번호*: ${fileData.practiceCallNumber}\n- *선택된 대화성향 옵션*: ${fileData.partnerStyle}\n- *선택된 상담목적 옵션*: ${fileData.partnerPurpose}\n\n확인 후, 필요한 조치를 취해주시기 바랍니다. 감사합니다!\n`;
      await slackSendMessage(slackMessage);

      ReactGA.event({
        category: "TimeSelection Page",
        action: "Request Reservation",
        label: "Success - Reservation completed",
      });

      setIsLoading(false);
      navigate("/reservation-complete", {
        state: { status: "complete", practiceDateTime: koreaPracticeDateTime },
      });
    } else {
      alert("날짜, 시간, 분을 모두 선택해주세요.");
      setIsLoading(false);
    }
  };

  const minutes = [0, 10, 20, 30, 40, 50];

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.page}>
      <div className={styles.mainContainer}>
        <ResponsiveContainer
          width={880}
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <h2 className={styles.title}>
            연습할 시간을
            <br />
            선택해주세요
          </h2>

          <div className={styles.optionGroup}>
            <p className={styles.optionTitle}>날짜</p>
            <div className={styles.flexBlank} />
            <CustomDatePicker
              selectedDate={selectedDate}
              onChange={setSelectedDate}
            />
          </div>

          <div className={styles.optionGroup}>
            <p className={styles.optionTitle}>시간</p>
            <div className={styles.flexBlank} />
            <CustomTimePicker
              selectedTime={selectedTime}
              onChange={setSelectedTime}
            />
          </div>

          <div className={styles.optionGroup}>
            <p className={styles.optionTitle}>분</p>
            <div className={styles.flexBlank} />
            <div className={styles.optionButtonColumnGroup}>
              {Array.from(
                { length: Math.ceil(minutes.length / 3) },
                (_, groupIndex) => (
                  <div key={groupIndex} className={styles.optionButtonGroup}>
                    {minutes
                      .slice(groupIndex * 3, groupIndex * 3 + 3)
                      .map((minute) => (
                        <button
                          key={minute}
                          className={`${styles.minuteButton} ${
                            selectedMinute === minute ? styles.selected : ""
                          }`}
                          onClick={() => setSelectedMinute(minute)}
                        >
                          {minute}분
                        </button>
                      ))}
                  </div>
                )
              )}
            </div>
          </div>
          <div className={styles.flexBlank} />
          <div className={styles.optionGroup} style={{ marginBottom: 0 }}>
            <div className={styles.flexBlank} />
            <button
              className={`${styles.nextButton} ${
                selectedDate && selectedTime && selectedMinute !== null
                  ? styles.primary
                  : styles.disabled
              }`}
              onClick={handleNextClick}
              disabled={
                !selectedDate || !selectedTime || selectedMinute === null
              }
            >
              다음
            </button>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TimeSelection;
