import React from "react";
import styles from "./FileList.module.css";

const FileList = ({ files, onFileClick }) => {
    // Sort files by latest practice date
    const sortedFiles = files.sort(
        (a, b) => new Date(b.practiceDateTime) - new Date(a.practiceDateTime)
    );

    return (
        <div>
            <table className={styles.fileTable}>
                <thead>
                    <tr>
                        <th>예약 번호</th>
                        <th>연습 시간</th>
                        <th>상태</th>
                        <th>대화 성향</th>
                        <th>상담 목적</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedFiles.map((file) => (
                        <tr
                            key={file.fileId}
                            className={styles.fileItem}
                            onClick={() => onFileClick(file)}
                        >
                            <td>{file.name}</td>
                            <td>
                                <p>
                                    {new Date(file.practiceDateTime).toLocaleDateString("ko-KR", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })}
                                </p>
                                <p>
                                    {new Date(file.practiceDateTime).toLocaleTimeString("ko-KR", {
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    })}
                                </p>
                            </td>
                            <td>
                                <span className={`${styles.status} ${styles[file.status]}`}>
                                    {file.status === "notStarted" ? "시작 전" :
                                        file.status === "inProgress" ? "진행 중" :
                                            file.status === "complete" ? "완료" : "중단"}
                                </span>
                            </td>
                            <td>
                                <span className={`${styles.partnerStyle} ${styles[file.partnerStyle === "긍정적" ? "positive" :
                                    file.partnerStyle === "부정적" ? "negative" : "random"]}`}>
                                    {file.partnerStyle}
                                </span>
                            </td>
                            <td>{file.partnerPurpose || "랜덤"}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FileList;
