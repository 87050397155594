import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getFilesByUserId,
  getFilesByPartnerId,
  createFileInService,
  getFileInService,
  updateFileInService,
  deleteFileInService,
} from "../services/fileService";
import { FileModel } from "../models/FileModel";
import { useUser } from "./UserContext";
import fileDummyData from "../test/dummys/fileDummyData";

const FileContext = createContext();

// TODO - Delete: Dummy Test
const isDummyTest = false;

export const FileProvider = ({ children }) => {
  const [files, setFiles] = useState([]);
  const [partnerFiles, setPartnerFiles] = useState([]);
  const [teamFiles, setTeamFiles] = useState([]);
  const { userData } = useUser();

  // 사용자의 모든 파일을 초기화합니다.
  useEffect(() => {
    const initializeFiles = async () => {
      try {
        // TODO - Delete: Dummy Test
        if (isDummyTest) {
          setFiles(fileDummyData);
          setPartnerFiles(fileDummyData);
          return;
        }

        if (userData && userData.userId) {
          // Firestore에서 userId와 연관된 파일들 가져오기
          const filesFromDB = await getFilesByUserId(userData.userId);
          setFiles(filesFromDB);

          if (userData.role === "partner") {
            const partnerFilesFromDB = await getFilesByPartnerId(
              userData.userId
            );
            setPartnerFiles(partnerFilesFromDB);
          }
          // 추가: 팀원의 모든 파일 데이터를 병합

          const teamFilesData = await Promise.all(
            (userData.teamMembers || []).map((member) =>
              member.userId ? getFilesByUserId(member.userId) : []
            )
          );
          setTeamFiles(teamFilesData.flat());
        } else {
          setFiles([]);
          setPartnerFiles([]);
          setTeamFiles([]);
        }
      } catch (error) {
        console.error("파일 초기화 오류:", error);
        setFiles([]);
        setPartnerFiles([]);
        setTeamFiles([]);
      }
    };

    initializeFiles();
  }, [userData]); // userData가 변경될 때마다 초기화

  // 새로운 파일을 생성하고 목록에 추가합니다.
  const createFile = async (fileData) => {
    const newFile = new FileModel(fileData);
    try {
      await createFileInService(newFile);
      setFiles((prevFiles) => [...prevFiles, newFile]);
    } catch (error) {
      console.error("파일 추가 오류:", error);
    }
  };

  // 파일을 읽습니다.
  const getFile = async (fileId) => {
    try {
      const file = await getFileInService(fileId);
      return file;
    } catch (error) {
      console.error("파일 가져오기 오류:", error);
    }
  };

  // 파일을 업데이트하고 목록을 갱신합니다.
  const updateFile = async (fileId, updatedData) => {
    try {
      if (userData.role === "partner") {
        // Ensure it only updates partner files with this ID
        await updateFileInService(fileId, updatedData);
        setPartnerFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.fileId === fileId
              ? new FileModel({ ...file, ...updatedData })
              : file
          )
        );
      } else {
        // For regular users, update the standard files
        await updateFileInService(fileId, updatedData);
        setFiles((prevFiles) =>
          prevFiles.map((file) =>
            file.fileId === fileId
              ? new FileModel({ ...file, ...updatedData })
              : file
          )
        );
      }
    } catch (error) {
      console.error("파일 수정 오류:", error);
    }
  };

  // 파일을 삭제하고 목록에서 제거합니다.
  const deleteFile = async (fileId) => {
    try {
      await deleteFileInService(fileId);
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.fileId !== fileId)
      );
    } catch (error) {
      console.error("파일 삭제 오류:", error);
    }
  };

  return (
    <FileContext.Provider
      value={{
        files,
        partnerFiles,
        teamFiles,
        createFile,
        getFile,
        updateFile,
        deleteFile,
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

export const useFiles = () => useContext(FileContext);
