import React, { createContext, useState, useContext, useEffect } from "react";
import {
  registerUserInService,
  loginUserInService,
  logoutUserInService,
} from "../services/firebaseAuthService";
import {
  createUserInService,
  getUserInService,
  updateUserInService,
  getAvailablePartnerInService,
  addMemberToTeamInService,
  getUserByEmailInService,
  updateUserPhoneNumberInService,
} from "../services/userService";
import { UserModel } from "../models/UserModel";
import userDummyData from "../test/dummys/userDummyData";
import { getCurrentTimeWithMillisecondsISO } from "../utils/isoDateUtils";
const UserContext = createContext();
// TODO - Delete: Dummy Test
const isDummyTest = false;
// TODO - Delete: Maker Test
const isMakerTest = false;
export const UserProvider = ({ children }) => {
  const defaultUserData = new UserModel({
    userId: null,
    name: null,
    email: "",
    password: "",
    createdDate: null,
    isSubscribed: false,
    subscriptionStartDate: null,
    subscriptionEndDate: null,
    role: "customer",
    slackUserId: null,
    teamMembers: [],
    phoneNumber: "",
  });
  const [userData, setUserData] = useState(defaultUserData);
  /**
   * 이메일과 비밀번호로 사용자를 로그인합니다.
   * @param {String} email - 사용자 이메일
   * @returns {Promise<boolean | null>}
   */
  const loginUser = async (email) => {
    try {
      const response = await loginUserInService(email);
      // 로그인 서비스가 null 또는 예상치 못한 값을 반환할 경우 처리
      if (!response || !response.userId) {
        console.error(
          "Login service failed: userId is missing or invalid response"
        );
        localStorage.removeItem("email"); // 불필요한 로그인 정보 제거
        return false;
      }
      const { userId } = response;
      let user = await getUserInService(userId);
      // 데이터베이스에 사용자 정보가 없으면 새로 생성
      if (!user) {
        console.log("User not found in database. Creating a new user.");
        const newUser = new UserModel({
          userId,
          email,
          createdDate: getCurrentTimeWithMillisecondsISO(),
          isSubscribed: true,
          role: "customer",
          phoneNumber: "",
        });
        // 새 사용자 생성
        await createUserInService(newUser);
        user = newUser;
      }
      // 사용자 데이터 설정 및 로컬 스토리지 저장
      setUserData(user);
      localStorage.setItem("email", email);
      return true;
    } catch (error) {
      // 예외 발생 시 로컬 스토리지 정리 및 오류 로그 출력
      localStorage.removeItem("email");
      console.error("Error logging in user:", error);
      return null;
    }
  };
  useEffect(() => {
    let email = localStorage.getItem("email");
    const initializeUserData = async () => {
      try {
        // TODO - Delete: Dummy Test
        if (isDummyTest) {
          setUserData(userDummyData);
          return;
        }
        // TODO - Delete: Maker Test
        if (isMakerTest) {
          email = "rlfgus8708@gmail.com";
        }
        if (email) {
          // loginUser 함수 활용하여 사용자 로그인 및 데이터 설정
          await loginUser(email);
        }
      } catch (error) {
        console.error("Error initializing user data:", error);
      }
    };
    initializeUserData();
  }, []);
  const registerUser = async (email, phoneNumber) => {
    try {
      const { userId, password } = await registerUserInService(email);
      if (userId && password) {
        const newUser = new UserModel({
          userId,
          email,
          password,
          phoneNumber,
          createdDate: getCurrentTimeWithMillisecondsISO(),
        });
        await createUserInService(newUser);
        setUserData(newUser);
        localStorage.setItem("email", email);
      }
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };
  const updateUser = async (updatedData) => {
    try {
      await updateUserInService(userData.userId, updatedData);
      setUserData((prevData) => new UserModel({ ...prevData, ...updatedData }));
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const updatePartnerUser = async (updatedData) => {
    try {
      if (!updatedData.userId) {
        console.log("Update Data에 userId가 없음");
        return;
      }
      await updateUserInService(updatedData.userId, updatedData);
      setUserData((prevData) => new UserModel({ ...prevData, ...updatedData }));
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  /**
   * 팀 멤버를 추가합니다.
   * @param {string} email - 추가할 팀원의 이메일
   * @param {string} name - 추가할 팀원의 이름
   * @returns {Promise<{userId: string, email: string, name: string}>} - 추가된 팀원의 정보
   */
  const addMember = async (email, name) => {
    try {
      if (!userData.userId) {
        throw new Error("사용자 정보가 없습니다. 로그인이 필요합니다.");
      }

      // 이메일로 사용자의 userId를 가져옵니다.
      const member = await fetchUserByEmail(email);
      if (!member || !member.userId) {
        throw new Error("해당 이메일로 사용자를 찾을 수 없습니다.");
      }

      const { userId } = member;

      // 서비스 계층 호출: 팀 멤버 추가
      const newMember = await addMemberToTeamInService(
        userData.userId,
        email,
        name
      );

      // 상태 업데이트: teamMembers 배열
      setUserData((prev) => ({
        ...prev,
        teamMembers: [
          ...prev.teamMembers,
          { userId, email: newMember.email, name: newMember.name },
        ],
      }));

      return { userId, email: newMember.email, name: newMember.name };
    } catch (error) {
      console.error("Error adding member:", error);
      throw error;
    }
  };
  const logoutUser = async () => {
    try {
      await logoutUserInService();
      clearUserData();
    } catch (error) {
      console.error("Error logging out user:", error);
    }
  };
  const getAvailablePartner = async (reservationDateTime) => {
    try {
      const availablePartner = await getAvailablePartnerInService(
        reservationDateTime
      );
      return availablePartner;
    } catch (error) {
      console.error("Error getting available partner:", error);
      return null;
    }
  };

  const clearUserData = () => {
    setUserData(defaultUserData);
    localStorage.removeItem("email");
  };

  /**
   * 이메일로 사용자 정보 가져오기
   * @param {string} email - 조회할 사용자의 이메일
   * @returns {Promise<UserModel|null>}
   */
  const fetchUserByEmail = async (email) => {
    try {
      return await getUserByEmailInService(email);
    } catch (error) {
      console.error("Error fetching user by email:", error);
      return null;
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        registerUser,
        loginUser,
        updateUser,
        updatePartnerUser,
        logoutUser,
        addMember,
        getAvailablePartner,
        clearUserData,
        fetchUserByEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useUser = () => useContext(UserContext);
